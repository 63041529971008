document.addEventListener('DOMContentLoaded', () => {
    const scrollTopBtn = document.getElementById("bottom");
    const scrollTarget = document.getElementById("top");

    scrollTopBtn.addEventListener("click", function() {
      scrollTarget.scrollIntoView({
        behavior: "smooth"
      });
    });
});

class IpConfig {
constructor(config) {
this.config = config;
this.init();
}
async init() {
this.ipContainer = document.getElementsByClassName(
this.config.ipContainerClass
)[0];
this.errorMessage = this.config.errorMessage;
this.ipAddress = await this.getIPAddress();
this.ipContainer.value = this.ipAddress;
}

// Get IP Address from an external link to fetch the global IP
async getIPAddress() {
try {
const response = await fetch("https://api.ipify.org?format=json");
const data = await response.json();
if (data.ip) {
return data.ip;
} else {
alert(this.errorMessage);

3

}
} catch (error) {
console.error("Error:", error);
}
}
}

window.addEventListener("DOMContentLoaded", () => {
const ipConfig = new IpConfig({
ipContainerClass: "extern-Ip-Addr-Class",
errorMessage: "Ip could not be retrieved.",
errorAPIExec: "Posting to API was not possible",
});
});

setTimeout(function() {
      var button = document.getElementById("startbutton");
      button.classList.add("shake");
    }, 5000);
 
 window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {const d = new Date();

document.getElementById('input-9e18521a').value = d
		// Do something when the flow renders
	}
}


